import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Twitter: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg className={clss} viewBox="0 0 21 21">
            <g fill={color}>
                <path
                    fill={color}
                    d="M13.84 3h1.017c.803.18 1.534.502 2.133 1.103.056.057.205.06.293.03.692-.23 1.38-.472 2.071-.712-.047.119-.084.309-.185.449-.374.52-.77 1.022-1.147 1.515l1.835-.528c-.128.188-.2.332-.307.441-.426.438-.872.856-1.29 1.301a.853.853 0 00-.21.458c-.101.846-.094 1.716-.277 2.541-1.405 6.328-7.417 9.622-13.251 7.985A7.565 7.565 0 012 16.353a7.538 7.538 0 002.763-.27c.899-.265 1.736-.664 2.52-1.323-1.681-.188-2.797-1.02-3.396-2.575l1.506-.08C3.49 11.24 2.45 9.852 2.618 8.325c1.073.43 1.101.437 1.487.355-1.51-1.466-1.97-3.83-.95-4.924C5.147 6.13 7.65 7.455 10.734 7.67c-.014-.177-.02-.3-.034-.422-.16-1.38.311-2.5 1.32-3.402.525-.469 1.17-.666 1.82-.846"
                />
            </g>
        </svg>
    );
};

export default Twitter;
